'use client';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Auth0Client,
  User as Auth0User,
  GenericError,
} from '@auth0/auth0-spa-js';
import jwt_decode from 'jwt-decode';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useSearchParams } from 'next/navigation';
import {
  AuthenticationContext,
  AuthenticationContextType,
  initialValue,
} from './AuthenticationContext';
import { Auth0Role } from '@/api/graphql';
import { Spinner } from '@/common/Spinner';
import { publicApiJSON } from '@/common/utils/publicApi/publicApi';

type DecodedJWT = {
  'https://rellify:orgId': string;
  'https://rellify:userId': string;
  'https://rellify:auth0role': Auth0Role;
  'https://rellify:logging': boolean;
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  scope: string;
  org_id: string;
  azp: string;
};

const isUserRegisteredViaSocialProvider = (
  auth0User: Auth0User | undefined
): boolean => {
  return auth0User && auth0User.sub
    ? !auth0User.sub.startsWith('auth0|')
    : false;
};

const getOrganizationIdByName: (
  organizationName: string
) => Promise<{ organizationId: string }> = async (organizationName) =>
  publicApiJSON({
    path: 'getOrganizationId',
    method: 'POST',
    body: {
      organizationName,
    },
  });

type AuthenticationProviderProps = {
  auth0OrgIdOrName?: string;
} & PropsWithChildren;

type CheckAuthOptions = {
  /* Force refresh the token */
  ignoreCache: boolean;
};

export const AuthenticationProvider: FC<AuthenticationProviderProps> = ({
  children,
  auth0OrgIdOrName,
}) => {
  const searchParams = useSearchParams();

  const {
    invitation,
    organization: fromQuery,
    organization_name: organizationName,
    code,
    state,
  } = useMemo(
    () => ({
      invitation: searchParams.get('invitation') || undefined,
      organization: searchParams.get('organization') || undefined,
      organization_name: searchParams.get('organization_name') || undefined,
      code: searchParams.get('code') || undefined,
      state: searchParams.get('state') || undefined,
    }),
    [searchParams]
  );

  const [isAuthenticated, setIsAuthenticated] = useState(
    initialValue.isAuthenticated
  );
  const [user, setUser] = useState<Auth0User | undefined>(initialValue.user);

  const [allowRedirect, setAllowRedirect] = useState<boolean>(false);

  const [loggingEnabled, setLoggingEnabled] = useState<boolean>(false);

  const [claims, setClaims] = useState<
    AuthenticationContextType['claims'] | undefined
  >(initialValue.claims);

  const [jwtToken, setJwtToken] = useState<
    AuthenticationContextType['jwtToken'] | undefined
  >(initialValue.jwtToken);

  const [isRegisteredViaSocialProvider, setIsRegisteredViaSocialProvider] =
    useState(initialValue.isRegisteredViaSocialProvider);

  // Set the auth0 organization id
  const [isLoadingAuth0OrgId, setIsLoadingAuth0OrgId] = useState(true);
  const [auth0OrgId, setAuth0OrgId] = useState<
    AuthenticationContextType['auth0OrgId'] | undefined
  >(initialValue.auth0OrgId);

  useEffect(() => {
    setIsLoadingAuth0OrgId(true);

    // Signed in via /login-url
    if (auth0OrgIdOrName && !auth0OrgIdOrName.startsWith('org_')) {
      // Get organization id via name, e.g. if the user tried to sign in via /login/rellify
      getOrganizationIdByName(auth0OrgIdOrName)
        .then(({ organizationId }) => {
          setAuth0OrgId(organizationId);
          setIsLoadingAuth0OrgId(false);
        })
        .catch((error) => {
          console.error(
            '[AuthenticationProvider] Error in getOrganizationIdByName',
            error
          );
          setAuth0OrgId(undefined);
          setIsLoadingAuth0OrgId(false);
        });
    } else {
      setAuth0OrgId(() => {
        // Signed in via /login/org_myOrdId
        if (auth0OrgIdOrName) {
          return auth0OrgIdOrName;
        }

        // Signed in via /organizations/org_myOrdId/...
        const fromPath = location.pathname
          ?.split('/organizations/')?.[1]
          ?.split('/')?.[0];
        if (fromPath) {
          return fromPath;
        }

        // Signed in via ...?organization=org_myOrdId
        if (fromQuery) {
          return fromQuery;
        }

        return undefined;
      });
      setIsLoadingAuth0OrgId(false);
    }
  }, [auth0OrgIdOrName, fromQuery]);

  const [client, setClient] = useState<Auth0Client | undefined>();
  useEffect(() => {
    if (isLoadingAuth0OrgId) {
      setClient(undefined);
    } else {
      setClient(
        new Auth0Client({
          domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
          client_id: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
          useRefreshTokens: true,
          redirect_uri: window.location.origin,
          organization: auth0OrgId,
          cacheLocation: 'localstorage',
        })
      );
    }
  }, [auth0OrgId, isLoadingAuth0OrgId]);

  const checkAuth = useCallback(
    async (options?: CheckAuthOptions) => {
      // If the user isn't authenticated, redirect to the sign in page
      const signIn = async () => {
        await client?.loginWithRedirect({
          invitation,
          organization: auth0OrgId,
          organization_name: organizationName,
          appState: {
            targetUrl: location.pathname, // save path to redirect user after login is successful
          },
        });
      };

      try {
        const token = await client?.getTokenSilently(options);
        if (token) {
          const decoded = jwt_decode(token) as DecodedJWT;
          const auth0OrgIdFromToken = decoded.org_id;
          if (auth0OrgId && auth0OrgIdFromToken !== auth0OrgId) {
            // The organization ids differ, so we have to sign in the user again
            await signIn();
          } else {
            // Set the claims based on the information in the token
            setClaims({
              organizationId: decoded['https://rellify:orgId'],
              userId: decoded['https://rellify:userId'],
              auth0Role: decoded['https://rellify:auth0role'],
              logging: decoded['https://rellify:logging'] !== false,
            });
            setJwtToken(token);
            setAuth0OrgId(auth0OrgIdFromToken);

            // Get the auth0 user information
            const auth0User = await client?.getUser();
            setUser(auth0User);
            setIsRegisteredViaSocialProvider(
              isUserRegisteredViaSocialProvider(auth0User)
            );

            // After everything was checked, set the is authenticated flag
            setIsAuthenticated(true);
          }
        }
      } catch (error: any) {
        if (
          !(error instanceof GenericError) ||
          error.error !== 'login_required'
        ) {
          console.error('[AuthenticationProvider] Error in checkAuth', error);
        }
        // Disable logging, e.g. if the user signed out
        setLoggingEnabled(false);

        // Sign in the user, if they aren't authenticated
        setAllowRedirect(true);
        await signIn();
      }
    },
    [auth0OrgId, client, invitation, organizationName]
  );

  useEffect(() => {
    if (client) {
      if (code && state) {
        client
          .handleRedirectCallback()
          .then(async (v) => {
            // After login
            await checkAuth();

            if (
              v.appState.targetUrl &&
              !v.appState.targetUrl.includes('/login')
            ) {
              window.location.href = v.appState.targetUrl;
            }
          })
          .catch((error: any) => {
            console.error(
              '[AuthenticationProvider] Error in handleRedirectCallback',
              error
            );
            // Reload the page if an unexpected error occurred
            // window.location.href = window.location.origin;
          });
      } else {
        checkAuth();
      }
    }
  }, [
    auth0OrgId,
    checkAuth,
    client,
    code,
    invitation,
    jwtToken,
    organizationName,
    state,
  ]);

  useEffect(() => {
    if (jwtToken) {
      const decoded = jwt_decode(jwtToken) as DecodedJWT;
      const expirationTime = decoded.exp * 1000;
      const currentTime = Date.now();
      const millisecondsUntilExpiration = expirationTime - currentTime;

      const timeoutId = setTimeout(async () => {
        checkAuth({ ignoreCache: true });
      }, millisecondsUntilExpiration - 60000); // Refresh the token 1 minute before it expires

      return () => clearTimeout(timeoutId);
    }
  }, [jwtToken, client, checkAuth]);

  const loadedChildren =
    isAuthenticated && !allowRedirect && !!auth0OrgId && !!claims && !!jwtToken;

  // Logging
  useEffect(() => {
    // Logging is enabled for the user
    if (
      claims &&
      claims.logging &&
      (process.env.NEXT_PUBLIC_NODE_ENV !== 'development' ||
        process.env.NEXT_PUBLIC_DEBUG_LOGGING)
    ) {
      // Init logging
      LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET, {
        release: process.env.NEXT_PUBLIC_BUILD_NUMBER,
        dom: {
          privateAttributeBlocklist: ['data-sanitized'],
        },
        network: {
          requestSanitizer: (request) => ({
            ...request,
            headers: Object.fromEntries(
              Object.entries(request.headers).filter(
                ([key]) => key.toLowerCase() !== 'authorization'
              )
            ),
            body: request.body?.includes('applicationPassword')
              ? 'SANITIZED'
              : request.body,
          }),
        },
      });
      setupLogRocketReact(LogRocket);

      // Identify the user based on the given claims (same across all sessions)
      LogRocket.identify(claims.userId);
      // LogRocket.identify(claims.userId, {
      //   nickname: user?.nickname ?? '',
      //   email: user?.email ?? '',
      //   organizationId: claims.organizationId,
      //   role: claims.auth0Role,
      // });

      // Track organization related information not user- but session-based, because a user could log into multiple organizations
      LogRocket.track('organization', {
        organizationId: claims.organizationId,
        role: claims.auth0Role,
      });

      // Enable logging
      setLoggingEnabled(true);
    } else {
      // Disable logging
      setLoggingEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(claims)]); // Only trigger, if the claims are different

  return (
    <AuthenticationContext.Provider
      value={{
        client,
        isAuthenticated,
        user,
        isRegisteredViaSocialProvider,
        claims,
        jwtToken,
        auth0OrgId,
        loggingEnabled,
      }}
    >
      {loadedChildren ? children : <Spinner />}
    </AuthenticationContext.Provider>
  );
};
